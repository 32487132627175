html {
	position: relative;
  min-height: 100%;
	/*height: 100%;*/
  /*margin: 0;*/
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}

body {
  background-color: #282c34;
  /*margin-bottom: 105px;*/
}

/*code {*/
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
    /*monospace;*/
/*}*/

.shadow-light {
  box-shadow: 0px 0px 2px 0px #FFF4E9ff;
}

.shadow-dark {
  box-shadow: 0px 0px 3px 0px #555;
}
